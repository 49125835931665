<template>
  <div class="nav-item-wrapper">
    <b-nav-item v-if="link.text === 'Bli kund'" :href="link.href" target="_blank" :rel="link.rel"
      @click="clickHref(link)">
      <b-button variant="purple" class="hover-dark active-dark">
        {{ link.text }}
      </b-button>
    </b-nav-item>

    <b-nav-item v-else-if="link.href" :href="link.href" :rel="link.rel" target="_blank" @click="clickHref(link)">
      <b-button class="hover-dark active-pink">
        {{ link.text }}
      </b-button>
    </b-nav-item>

    <b-nav-item v-else-if="link.text === 'Fakturerings-& bokföringsprogram'" @mouseover="displayUnder(true)"
      @mouseleave="displayUnder(false)" :rel="link.rel" @click="handleClick(link)" :class="{
      purpleHover: currentPath === link.route
    }">
      <b-nav-text :class="{
      active: currentPath === link.route && currentPath != 'login'
    }">
        {{ link.text }}
      </b-nav-text>
    </b-nav-item>

    <b-nav-item v-else :rel="link.rel" @click="handleClick(link)" :class="{
      purpleHover: currentPath === link.route
    }">
      <b-nav-text :class="{
      active: currentPath === link.route && currentPath != 'login'
    }">
        {{ link.text }}
      </b-nav-text>
    </b-nav-item>
    <div v-if="link.text === 'Fakturerings-& bokföringsprogram'"
      v-show="viewWidth < xl || (viewWidth >= xl && displayUnderMenu)" @mouseover="displayUnder(true)"
      @mouseleave="displayUnder(false)">
      <ProductMenu :navOpen="navOpen" @update-nav-open="updateNavOpen" />
    </div>
  </div>
</template>

<script>
import ProductMenu from './common/ProductMenu.vue';
import breakpoints from "@/constants/breakpoints";
export default {
  name: "HeaderLink",

  props: ["link", "pageType", "navOpen"],

  data() {
    return {
      displayUnderMenu: false
    }
  },

  components: {
    ProductMenu
  },

  computed: {
    viewWidth() {
      return this.$store.state.base.viewWidth;
    },
    xl: () => breakpoints.xl,
    currentPath() {
      return this.$route.path;
    },

    onHomePage() {
      return this.currentPath == "/";
    },

    onEuroFinansPage() {
      return this.currentPath === "/eurofinans";
    },

    onPrioritetFinansPage() {
      return this.currentPath === "/prioritetfinans";
    },

    onAllHomePages() {
      return (
        this.onHomePage || this.onEuroFinansPage || this.onPrioritetFinansPage
      );
    }
  },

  methods: {
    updateNavOpen(state) {
      this.$emit('update-nav-open', state)
     },
    displayUnder(toggle) {
      toggle ? this.displayUnderMenu = true : this.displayUnderMenu = false
    },
    handleClick(link) {
      this.$gtm.trackEvent({
        event: "navigation_links",
        navigationLink: link.text,
        pageType: this.pageType
      });

      if (this.$route.path !== link.to) this.$router.push(link.to);
    },
    clickHref(link) {
      this.$gtm.trackEvent({
        event: "navigation_links",
        navigationLink: link.text,
        pageType: this.pageType
      });
    }
  }
};
</script>

<style></style>
