<template>
    <section class="productMenu-container">
        <div v-for="item in navItems">
            <p :class="{ active: currentPath === item.route }" @click="handleClick(item.route)">{{ item.text }}</p>
        </div>
    </section>
</template>

<script>
export default {
    name: "productMenu",
    props: ['navOpen'],
    computed: {
        viewWidth() {
            return this.$store.state.base.viewWidth;
        },
        currentPath() {
            return this.$route.path;
        },
        navItems() {
            return [
                {
                    route: "/fakturerings-bokforingsprogram/fakturering",
                    text: "Fakturering",
                    to: "/fakturerings-bokforingsprogram/fakturering"
                },
                {
                    route: "/fakturerings-bokforingsprogram/bokforing",
                    text: "Bokföring",
                    to: "/fakturerings-bokforingsprogram/bokforing"
                },
                {
                    route: "/fakturerings-bokforingsprogram/offert",
                    text: "Offert",
                    to: "/fakturerings-bokforingsprogram/offert"
                },
                {
                    route: "/fakturerings-bokforingsprogram/bankkoppling",
                    text: "Bankkoppling",
                    to: "/fakturerings-bokforingsprogram/bankkoppling"
                },
                {
                    route: "/fakturerings-bokforingsprogram/rapporter",
                    text: "Rapporter",
                    to: "/fakturerings-bokforingsprogram/rapporter"
                },
            ]
        }
    },

    methods: {
        handleClick(link) {
            if(this.viewWidth < 1200){
                this.$emit('update-nav-open', !this.navOpen)
            }
            this.$gtm.trackEvent({
                event: "navigation_links",
                navigationLink: link.text
            });
            if (this.$route.path !== link) this.$router.push(link);
        }
    }
}
</script>